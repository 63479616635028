import React from "react"

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div
    className="video"
    style={{
      overflow: "hidden",
      paddingBottom: "56.25%",
      position: "relative",
      height: "0",
      width: "90%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "10px auto",
    }}
  >
    <iframe
      style={{
        left: "0",
        top: "0",
        height: "100%",
        width: "100%",
        position: "absolute",
        border: "solid 1px white",
      }}
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)
export default Video
