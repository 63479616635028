import React from "react"
import Layout from "../components/layout"
import Video from "../components/video"

const musicVideo = () => {
  return (
    <Layout>
      <Video
        videoSrcURL="https://www.youtube.com/embed/videoseries?list=PLtL8f9sdXHp-9zfRZNlIkvANqtdCO3u-4"
        videoTitle="I Love you Raja"
      />
    </Layout>
  )
}

export default musicVideo
